import React, { useState, useEffect } from "react";
import { Input, Button, Typography, Card, Row, Col, Form } from "antd";

const { Title, Paragraph } = Typography;

const InputForm = ({ onSubmit, initialValues }) => {
  const [url, setUrl] = useState(initialValues?.url || "");
  const [email, setEmail] = useState(initialValues?.email || "");
  const [customRequest, setCustomRequest] = useState(
    initialValues?.customRequest || ""
  );

  useEffect(() => {
    if (initialValues) {
      setUrl(initialValues.url || "");
      setEmail(initialValues.email || "");
      setCustomRequest(initialValues.customRequest || "");
    }
  }, [initialValues]);

  const handleSubmit = () => {
    if (!url.trim() || !email.trim() || !customRequest.trim()) {
      alert("All fields are required.");
      return;
    }
    onSubmit({ url, email, customRequest });
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Card
          style={{
            textAlign: "center",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "20px",
          }}
        >
          <Title level={3}>Enter YouTube URL</Title>
          <Paragraph>
            Provide a YouTube video URL, your email, and describe what kind of
            moments you want clipped from the video.
          </Paragraph>
          <Form layout="vertical">
            <Form.Item label="YouTube URL" required>
              <Input
                placeholder="Paste YouTube URL here"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Email Address" required>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="What moments do you want clipped?" required>
              <Input.TextArea
                rows={3}
                placeholder="E.g., 'all goals and celebrations' or 'funny moments'"
                value={customRequest}
                onChange={(e) => setCustomRequest(e.target.value)}
              />
            </Form.Item>
            <Button type="primary" onClick={handleSubmit} block>
              Submit
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default InputForm;
