import React, { useState, useEffect } from "react";
import { Button, Card, Typography, Image, Alert } from "antd";

const { Title, Paragraph } = Typography;

const ConfirmationPage = ({ formData, onConfirm, onEdit }) => {
  const [emailValid, setEmailValid] = useState(true);
  const [urlValid, setUrlValid] = useState(true);

  useEffect(() => {
    // Validate email
    const validateEmail = () =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    setEmailValid(validateEmail());

    // Validate YouTube URL
    const validateYouTubeURL = () => {
      const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
      return regex.test(formData.url);
    };
    setUrlValid(validateYouTubeURL());
  }, [formData]);

  return (
    <Card
      style={{
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "20px",
      }}
    >
      <Title level={3}>Confirm Your Submission</Title>
      <Paragraph>
        Please review the details below. If everything looks good, confirm your
        submission.
      </Paragraph>

      <Image
        src={formData.thumbnail}
        alt="Video Thumbnail"
        style={{ marginBottom: "20px", maxWidth: "100%" }}
      />

      {!emailValid && <Alert message="Invalid email address." type="error" />}
      {!urlValid && <Alert message="Invalid YouTube URL." type="error" />}

      <Paragraph>
        <strong>Video URL:</strong> <br />
        {formData.url}
      </Paragraph>

      <Paragraph>
        <strong>Email:</strong> <br />
        {formData.email}
      </Paragraph>

      <Paragraph>
        <strong>Custom Request:</strong> <br />
        {formData.customRequest}
      </Paragraph>

      <div style={{ marginTop: "20px" }}>
        <Button
          type="primary"
          onClick={onConfirm}
          disabled={!emailValid || !urlValid}
          style={{ marginRight: "10px" }}
        >
          Confirm
        </Button>
        <Button onClick={onEdit}>Edit</Button>
      </div>
    </Card>
  );
};

export default ConfirmationPage;
