import { createSlice } from "@reduxjs/toolkit";

const videoSlice = createSlice({
  name: "video",
  initialState: { clips: [], loading: false, error: null },
  reducers: {
    startProcessing: (state) => {
      state.loading = true;
      state.error = null;
    },
    processingSuccess: (state, action) => {
      state.loading = false;
      state.clips = action.payload;
    },
    processingFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { startProcessing, processingSuccess, processingFailed } =
  videoSlice.actions;

export default videoSlice.reducer;
