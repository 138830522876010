import React, { useState } from "react";
import { useDispatch } from "react-redux";
import InputForm from "../components/InputForm";
import ConfirmationPage from "../components/ConfirmationPage";
import {
  startProcessing,
  processingSuccess,
  processingFailed,
} from "../store/slices/videoSlice";
import { processVideo } from "../api/videoApi";

const HomePage = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(null); // Stores form data
  const [isEditing, setIsEditing] = useState(true); // Controls whether we're in edit mode
  const [submissionStatus, setSubmissionStatus] = useState(null); // Tracks submission status

  // Step 1: Handle initial form submission
  const handleInitialSubmit = (data) => {
    const extractYouTubeID = (url) => {
      const match = url.match(
        /(?:https?:\/\/)?(?:www\.)?youtu(?:be\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|\.be\/)([a-zA-Z0-9_-]{11})/
      );
      return match ? match[1] : null;
    };

    const thumbnailUrl = `https://img.youtube.com/vi/${extractYouTubeID(
      data.url
    )}/0.jpg`;
    setFormData({ ...data, thumbnail: thumbnailUrl });
    setIsEditing(false); // Switch to confirmation page
    setSubmissionStatus(null); // Reset submission status
  };

  // Step 2: Handle final submission
  const handleFinalSubmit = async () => {
    if (!formData) return;
    dispatch(startProcessing());
    try {
      const response = await processVideo({
        url: formData.url,
        email: formData.email,
        customRequest: formData.customRequest,
      });
      if (response.success) {
        dispatch(processingSuccess(response.clips));
        setSubmissionStatus("success");
        setFormData(null); // Clear form data after success
        setIsEditing(true); // Reset to editing mode for new entries
      } else {
        throw new Error(response.message || "Submission failed.");
      }
    } catch (err) {
      dispatch(processingFailed(err.message));
      setSubmissionStatus("error");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      {/* Render InputForm if in editing mode */}
      {isEditing && (
        <InputForm
          onSubmit={handleInitialSubmit}
          initialValues={formData} // Pre-fill form fields with preserved data
        />
      )}

      {/* Render ConfirmationPage if not editing */}
      {!isEditing && formData && (
        <ConfirmationPage
          formData={formData}
          onConfirm={() => {
            handleFinalSubmit();
          }}
          onEdit={() => {
            setIsEditing(true); // Switch back to editing mode
          }}
        />
      )}

      {/* Submission Status */}
      {submissionStatus === "success" && <p>Submission successful!</p>}
      {submissionStatus === "error" && <p>Submission failed. Try again.</p>}
    </div>
  );
};

export default HomePage;
