import React from "react";
import { useSelector } from "react-redux";
import { List, Typography, Alert } from "antd";

const { Title } = Typography;

const ResultsPage = () => {
  // Access the Redux state for clips and error
  const { clips, error } = useSelector((state) => state.video);

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <Title level={2}>Processed Clips</Title>

      {/* Display an error if something went wrong */}
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          style={{ marginBottom: "20px" }}
        />
      )}

      {/* Show a message if there are no clips */}
      {clips.length === 0 && !error && (
        <Alert
          message="No Clips Found"
          description="Please submit a video URL on the home page to process."
          type="info"
          showIcon
        />
      )}

      {/* Display the list of clips */}
      {clips.length > 0 && (
        <List
          bordered
          dataSource={clips}
          renderItem={(clip, index) => (
            <List.Item>
              <a
                href={clip.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "16px" }}
              >
                Clip {index + 1}
              </a>
            </List.Item>
          )}
          style={{ marginTop: "20px" }}
        />
      )}
    </div>
  );
};

export default ResultsPage;
