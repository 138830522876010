import HomePage from "./pages/HomePage";
import ResultsPage from "./pages/ResultsPage";

const routes = [
  {
    path: "/",
    component: HomePage, // Homepage with input form and processing status
  },
  {
    path: "/results",
    component: ResultsPage, // Results page to display processed clips
  },
];

export default routes;
