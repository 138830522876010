export const processVideo = async (youtubeUrlObj) => {
  try {
    const { url, email, customRequest } = youtubeUrlObj; // Destructure the input object

    const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1:5001"; // Default fallback
    const response = await fetch(`${apiUrl}/create-job`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        youtube_url: url, // Extracted URL
        email: email, // Extracted email
        custom_request: customRequest, // Extracted custom request
      }),
    });
    if (!response.ok) throw new Error("Error processing video.");
    return await response.json();
  } catch (error) {
    console.error("Error in processVideo:", error.message);
    throw error;
  }
};
